<template>
  <div class="auth-content">
    <div class="inner">
      <h2>Sign in</h2>

      <p class="font-medium">
        <b>Not a member yet? </b
        ><router-link :to="links.signup"><b>Create an account</b></router-link
        >.
      </p>

      <a-form-model
        class="auth-content-form"
        @submit.prevent="login"
        ref="form"
        :model="form"
        layout="vertical"
        v-dnd-classes
      >
        <a-form-model-item
          prop="email"
          label="Email address"
          help="Must be unique"
        >
          <a-input
            size="large"
            spellcheck="false"
            v-model="form.email"
            placeholder="e.g: john@acme.com"
            v-autofocus
          />
        </a-form-model-item>

        <a-form-model-item label="Password" prop="password" class="no-margin">
          <a-input
            type="password"
            size="large"
            spellcheck="false"
            v-model="form.password"
            placeholder="••••••"
          >
            <icon slot="suffix" name="lock" size="14" class="fade-40" />
          </a-input>
        </a-form-model-item>

        <p class="color-gray no-margin">
          Forgot your password?
          <router-link :to="links.reset_password"><b>Reset it.</b></router-link
          >.
        </p>

        <a-button
          htmlType="submit"
          size="large"
          :loading="working"
          type="primary"
          icon="check"
          class="margin-top-base"
        >
          <b> Sign in</b></a-button
        >

        <p class="font-xs color-gray margin-top-base">
          By using GlassHouse up you agree to our
          <a href="/terms">Terms of service</a> and
          <a href="/privacy">Privacy policy</a>.
        </p>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  props: ["links"],

  components: {},

  data() {
    return {
      working: false,
      form: {},

      inviter_account: window._prefetch_data.inviter_account,
    };
  },

  created() {
    this.reset_form();
  },

  methods: {
    login() {
      if (this.working) {
        return;
      }

      this.working = true;

      this.$axios
        .post("/auth/do_login", this.form)
        .then((rsp) => {
          rsp = rsp.data;

          if (rsp.status.code == 200) {
            window.location = "/app";
          } else {
            this.working = false;
            this.articulate_error(rsp);
          }
        })
        .catch((err) => {
          this.working = false;
          this.render_error("Something went wrong");
        });
    },

    reset_form(seed = null) {
      this.$set(this, "form", {
        email: null,
        password: null,
        invite_code: this.$route.query.invite_code,
      });
    },
  },

  computed: {},

  watch: {},
};
</script>
