<template>
  <div class="auth-content">
    <div class="inner">
      <h2>Did you forget your password?</h2>

      <p class="font-medium">
        <b>Want to login instead? </b
        ><router-link :to="links.login"><b>Click here</b></router-link
        >.
      </p>

      <a-form-model
        class="auth-content-form"
        @submit.prevent="reset_password"
        ref="form"
        :model="form"
        layout="vertical"
        v-dnd-classes
      >
        <a-form-model-item prop="email" label="Email address" class="no-margin">
          <a-input
            size="large"
            spellcheck="false"
            v-model="form.email"
            placeholder="e.g: john@acme.com"
            v-autofocus
          />
        </a-form-model-item>

        <a-button
          htmlType="submit"
          size="large"
          :loading="working"
          type="primary"
          icon="check"
          class="margin-top-base"
        >
          <b> Reset my password</b></a-button
        >

        <p class="font-xs color-gray margin-top-base">
          By using GlassHouse up you agree to our
          <a href="/terms">Terms of service</a> and
          <a href="/privacy">Privacy policy</a>.
        </p>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  props: ["links"],

  components: {},

  data() {
    return {
      working: false,
      form: {},
    };
  },

  created() {
    this.reset_form();
  },

  methods: {
    reset_password() {
      if (this.working) {
        return;
      }

      this.working = true;

      this.$axios
        .post("/auth/do_reset_password", this.form)
        .then((rsp) => {
          rsp = rsp.data;

          this.articulate_response(rsp);

          if (rsp.status.code == 200) {
            this.$router.push("/auth/login");
          } else {
            this.working = false;
          }
        })
        .catch((err) => {
          this.working = false;
          this.render_error("Something went wrong");
        });
    },

    reset_form(seed = null) {
      this.$set(this, "form", {
        email: null,
      });
    },
  },

  computed: {},

  watch: {},
};
</script>
