import { Vue } from "./common/vue";
require("./components/auth/mixins");

window._ = require("./common/lodash").default;

import {
  ConfigProvider,
  Tag,
  Input,
  Checkbox,
  Select,
  Tabs,
  Tooltip,
  Row,
  Col,
  Icon,
  Button,
  Dropdown,
  Popover,
  Popconfirm,
  Menu,
  Avatar,
  Modal,
  FormModel,
  Badge,
  AutoComplete,
  Descriptions,
  Radio,
  Form,
  InputNumber,
  Switch,
  Spin,
  message,
} from "ant-design-vue";

[
  ConfigProvider,
  Tag,
  Input,
  Checkbox,
  Select,
  Tabs,
  Tooltip,
  Row,
  Col,
  Icon,
  Button,
  Dropdown,
  Popover,
  Popconfirm,
  Menu,
  Avatar,
  Modal,
  FormModel,
  Badge,
  AutoComplete,
  Descriptions,
  Radio,
  Form,
  InputNumber,
  Switch,
  Spin,
].forEach((c) => {
  Vue.use(c);
});

message.config({ duration: 5, maxCount: 3 });
Vue.prototype.$message = message;

import icon from "./components/common/icon.vue";
Vue.component("icon", icon);

import user_avatar from "./components/common/user_avatar.vue";
Vue.component("user-avatar", user_avatar);

import nullableSelect from "./components/common/nullable_select.vue";
Vue.component("a-nullable-select", nullableSelect);

import VueRouter from "vue-router";
Vue.use(VueRouter);

import { routes } from "./routes/auth";

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

import Auth from "./components/auth.vue";

new Vue({
  el: "#auth",
  render: (h) => h(Auth),
  router,
});

document.addEventListener(
  "keydown",
  function (e) {
    if (e.keyCode == 119) {
      // F8
      // eslint-disable-next-line no-debugger
      debugger;
    }
  },
  {
    capture: true,
  },
);
