import Login from "../components/auth/login.vue";
import Signup from "../components/auth/signup.vue";
import ResetPassword from "../components/auth/reset_password.vue";

const routes = [
  { path: "/auth/login", component: Login, name: "login" },
  { path: "/i/:invite_code", component: Signup, name: "signup" },
  { path: "/auth/signup", component: Signup, name: "signup" },
  {
    path: "/auth/reset_password",
    component: ResetPassword,
    name: "reset_password",
  },
];

export { routes };
