<template>
  <div class="auth-content">
    <div class="inner">
      <h2>{{ cta_headline }}.</h2>

      <a-row :gutter="20" type="flex" class="margin-bottom-base color-gray">
        <a-col>
          <icon name="check" size="16" />
          Free trial
        </a-col>
        <a-col>
          <icon name="check" size="16" />
          No credit card required
        </a-col>
        <a-col>
          <icon name="check" size="16" />
          Cancel anytime
        </a-col>
      </a-row>

      <p class="font-medium">
        <b>Are you already a member? </b>
        <router-link :to="links.login"><b>Login now</b></router-link>
        .
      </p>

      <a-form-model
        class="auth-content-form"
        @submit.prevent="save"
        ref="form"
        :model="form"
        :rules="rules"
        layout="vertical"
        v-dnd-classes
      >
        <a-form-model-item
          prop="email"
          label="Email address"
          help="Must be unique"
        >
          <a-input
            size="large"
            spellcheck="false"
            v-model="form.email"
            placeholder="e.g: john@acme.com"
            v-autofocus
          />
        </a-form-model-item>

        <a-form-model-item label="Choose a password" prop="password">
          <a-input
            type="password"
            size="large"
            spellcheck="false"
            v-model="form.password"
            placeholder="make it secure"
          >
            <icon slot="suffix" name="lock" size="14" class="fade-40" />
          </a-input>
        </a-form-model-item>

        <a-form-model-item
          prop="full_name"
          label="Your full name"
          class="no-margin"
        >
          <a-input
            size="large"
            spellcheck="false"
            v-model="form.full_name"
            placeholder="e.g: John Smith"
          />
        </a-form-model-item>

        <a-button
          htmlType="submit"
          size="large"
          :loading="saving"
          type="primary"
          icon="check"
          class="margin-top-base"
        >
          <b> {{ cta_button }}</b></a-button
        >

        <p class="font-xs color-gray margin-top-base">
          By signing up you agree to our
          <a href="/terms">Terms of service</a> and
          <a href="/privacy">Privacy policy</a>.
        </p>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  props: ["links"],

  components: {},

  data() {
    return {
      saving: false,
      form: {},

      rules: {
        full_name: [
          {
            required: true,
            message: "Please enter your name",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter a password",
            trigger: "blur",
          },
          {
            min: 6,
            message: "Password must be at least 6 characters long",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter an email address",
            trigger: "blur",
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please enter a valid email",
            trigger: "blur",
          },
        ],
      },

      inviter_account: window._prefetch_data.inviter_account,
    };
  },

  created() {
    this.reset_form();
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;

          this.$axios
            .post("/auth/do_signup", this.form)
            .then((rsp) => {
              rsp = rsp.data;

              if (rsp.status.code === 200) {
                window.location = "/app";
              } else {
                this.saving = false;
                this.articulate_error(rsp);
              }
            })
            .catch((err) => {
              this.saving = false;
              this.articulate_error(err);
            });
        }
      });
    },

    reset_form(seed = null) {
      this.$set(this, "form", {
        email: null,
        password: null,
        full_name: null,
        invite_code: this.$route.query.invite_code,
      });
    },
  },

  computed: {
    cta_headline() {
      return this.inviter_account
        ? `Join ${this.inviter_account.company}`
        : "Try Glasshouse for free";
    },

    cta_button() {
      return "Create my account";
    },
  },

  watch: {},
};
</script>
