<template>
  <div id="auth">
    <a href="/"><img src="../../images/logo_symbol.svg" class="auth-logo" /></a>

    <router-view :links="links"></router-view>

    <div class="auth-sider">
      <div class="inner">
        <div class="auth-sider-text">
          <h2><b>Simplify Selections</b>.</h2>
          <h6 class="color-gray margin-top-xs margin-bottom-base">
            Trusted by builders, designers and facility owners to streamline the
            options management process.
          </h6>
        </div>

        <img
          src="../../images/team_illustration.png"
          width="582"
          srcset="
            ../../images/team_illustration.png    1x,
            ../../images/team_illustration@2x.png 2x
          "
          class="auth-sider-illustration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "../../css/4-vendors/antd_auth.less";
import "../../css/auth.scss";

export default {
  name: "GlAuth",
  created() {
    this.$axios.defaults.headers.common["X-CSRF-Token"] = (
      document.querySelector("meta[name=csrf-token]") || {}
    ).content;
  },

  computed: {
    links() {
      let params = "";

      if (this.$route.query.invite_code) {
        params = `?invite_code=${this.$route.query.invite_code}`;
      }

      return {
        login: `/auth/login${params}`,
        signup: `/auth/signup${params}`,
        reset_password: `/auth/reset_password${params}`,
      };
    },
  },
};
</script>
